<template>
  <div class="quote"><slot></slot></div>
</template>

<script>
export default {
  name: 'quote'
}
</script>

<style scoped>
.quote {
  border-left: 3px solid #ff6d6d;
  background-color: #fbfbfb;
  padding: 15px 20px;
  border-radius: 3px;
  line-height: 32px;
}
</style>
