<template>
  <div class="home">
    <banner isHome="true" v-if="IsPC()"></banner>
    <div class="site-content animate">
      <!--通知栏-->
      <div class="notify">
        <div class="search-result" v-if="hideSlogan">
          <span v-if="searchWords">搜索结果："{{ searchWords }}" 相关文章</span>
          <span v-else-if="category">分类 "{{ category }}" 相关文章</span>
        </div>
        <quote v-else>“向上的路并不拥挤，大多数人选择了安逸”</quote>
      </div>

      <!--焦点图-->
      <div class="top-feature" v-if="!hideSlogan">
        <section-title>
          <div style="display: flex; align-items: flex-end">聚焦<small-ico></small-ico></div>
        </section-title>
        <div class="feature-content">
          <div class="feature-item" v-for="item in features" :key="item.title">
            <Feature :data="item"></Feature>
          </div>
        </div>
      </div>
      <!--文章列表-->
      <main class="site-main" :class="{ search: hideSlogan }" v-loading="dataListLoading">
        <section-title v-if="!hideSlogan">推荐</section-title>
        <template v-for="item in postList">
          <post :post="item" :key="item.id"></post>
        </template>
      </main>

      <!--加载更多-->
      <div class="more" v-show="hasNextPage">
        <div class="more-btn" @click="loadMore">More</div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner'
import Feature from '@/components/feature'
import sectionTitle from '@/components/section-title'
import Post from '@/components/post'
import SmallIco from '@/components/small-ico'
import Quote from '@/components/quote'
export default {
  name: 'Home',
  props: ['cate', 'words'],
  data() {
    return {
      features: [
        {
          id: 1,
          title: '全部文章',
          img: 'https://s21.ax1x.com/2024/06/25/pksaIk6.png',
          url: '/all'
        },
        {
          id: 2,
          title: '关于博主',
          img: 'https://s21.ax1x.com/2024/06/25/pksUvJU.png',
          url: '/about'
        }
        // {
        //   id: 3,
        //   title: '文章归档',
        //   img: 'https://s1.ax1x.com/2020/05/14/YDfT91.jpg',
        //   url: '/archive'
        // }
      ],
      postList: [],
      currPage: 1,
      pageSize: 10,
      signAddress: '',
      latitude: '',
      longitude: '',
      dataListLoading: true,
      errorPositionTxt: '',
      hasNextPage: true
    }
  },
  components: {
    Banner,
    Feature,
    sectionTitle,
    Post,
    SmallIco,
    Quote
  },
  computed: {
    searchWords() {
      return this.$route.params.words
    },
    category() {
      return this.$route.params.cate
    },
    hideSlogan() {
      return this.category || this.searchWords
    },
    notice() {
      return this.$store.getters.notice
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        this.init()
        this.getArticleList(val.name == 'search' ? val.params.words : null)
        if (val.name == 'all') this.pageSize = 9999
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent
      var Agents = new Array('Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod')
      var flag = true
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    },
    async getUsrPosition(callback) {
      await this.$http.setUsrPosition({
        content: callback,
        mailbox: this.getNowFormatDate()
      })
    },
    getNowFormatDate() {
      var date = new Date()
      var seperator1 = '-'
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      var hours = date.getHours()
      var minu = date.getMinutes()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate + ' ' + hours + ':' + minu
      return currentdate
    },
    getLocation() {
      let that = this
      const map = new AMap.Map('container', {
        resizeEnable: true
      })
      AMap.plugin('AMap.Geolocation', function () {
        const geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：5s
          buttonPosition: 'RB', //定位按钮的停靠位置
          buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          zoomToAccuracy: true //定位成功后是否自动调整地图视野到定位点
        })
        geolocation.getCurrentPosition(function (status, result) {
          if (status == 'complete') {
            that.onComplete(result)
          } else {
            that.onError(result)
          }
        })
      })
    },
    //解析定位结果
    onComplete(data) {
      this.signAddress = data.formattedAddress
      this.getUsrPosition(data.formattedAddress)
    },
    //解析定位错误信息
    onError(data) {
      console.log('定位失败:' + data.message)
    },
    init() {
      this.currPage = 1
      this.pageSize = 10
      this.postList = []
    },
    async loadMore() {
      if (this.hasNextPage) {
        this.pageSize = this.pageSize + 1
        this.getArticleList()
      }
    },
    async getArticleList(val) {
      try {
        var res = await this.$http.getArticle({
          pageNum: this.currPage,
          pageSize: this.pageSize,
          title: val ? val : ''
        })
        const dataList = res.data.list
        this.postList = [...this.postList, ...dataList]
        if (dataList.length < this.pageSize) {
          this.hasNextPage = false
        }
        this.dataListLoading = false
      } catch (err) {
        this.dataListLoading = false
      }
    }
  },
  mounted() {
    this.getLocation()
    this.init()
    if (this.$route.name == 'all') this.pageSize = 9999
    this.getArticleList(this.$route.name == 'search' ? this.$route.params.words : null)
  }
}
</script>
<style scoped lang="less">
.site-content {
  .notify {
    margin: 60px 0;
    border-radius: 3px;
    & > div {
      padding: 20px;
    }
  }

  .search-result {
    padding: 15px 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    border: 1px dashed #ddd;
    color: #828282;
  }
}

.top-feature {
  width: 100%;
  height: auto;
  margin-top: 30px;

  .feature-content {
    margin-top: 10px;
    display: flex;
    // justify-content: space-between;
    position: relative;

    .feature-item {
      width: 32.9%;
      margin-right: 20px;
    }
  }
}

.site-main {
  padding-top: 24px;

  &.search {
    padding-top: 0;
  }
}

.more {
  margin: 50px 0;
  .more-btn {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #adadad;
    border: 1px solid #adadad;
    border-radius: 20px;
    margin: 0 auto;
    cursor: pointer;
    &:hover {
      color: #8fd0cc;
      border: 1px dashed #8fd0cc;
    }
  }
}

/******/
@media (max-width: 800px) {
  .top-feature {
    display: none;
  }

  .site-main {
    padding-top: 40px;
  }

  .site-content {
    .notify {
      margin: 30px 0 0 0;
    }

    .search-result {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
}

/******/
</style>
